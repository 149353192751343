<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{showLang('com.edit.lamp.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="130">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')" style="width: 200px; margin-right: 20px;"></Input>
          所在灯杆
          <PoleSelect v-model="poleInfo" style="width: 150px; margin-left: 10px; margin-right: 36px;" />
          分组
          <Select :placeholder="showLang('save.select')" v-model="form.lightGroupId" style="width: 150px; margin-left: 10px; margin-right: 25px;">
            <template v-for="(item, idx) in args.groups">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
          应用类型
          <Select :placeholder="showLang('save.select')" v-model="form.useType" style="width: 150px; margin-left: 10px;">
            <template v-for="item, idx in args.useTypes">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="stationId" label="通信ID" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.code" placeholder="请输入通信ID" style="width: 150px; margin-right: 10px; margin-left: 10px;"></Input>
          {{showLang('com.date.installation')}}:<DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
        
        </div>
        
      </FormItem>
      <FormItem prop="env" :label="showLang('com.lighting.V.alarm')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.voltageLower" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          <Input type="number" v-model="form.voltageUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="ent" :label="showLang('com.lighting.T.alarm')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.tempLevel1" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.T.min')}}</span><span slot="append">℃</span></Input>
          <Input type="number" v-model="form.tempLevel2" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.T.max')}}</span><span slot="append">℃</span></Input>
          <Input type="number" v-model="form.tempLevel2Bright" style="width: 250px; margin: 0 5px;"><span slot="prepend">降功率调光值</span><span slot="append">℃</span></Input>
        </div>
      </FormItem>
      <template v-for="n in 2">
        <FormItem :key="`${n}-1`" prop="en1" :label="`${n}路控制参数`" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form[`enable${n}`]" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <Input type="number" v-model="form[`before${n}`]" style="width: 200px; margin-right:10px">
              <span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span>
              <span slot="append">W</span>
            </Input>
            <Input type="number" v-model="form[`after${n}`]" style="width: 200px; margin-right:10px">
              <span slot="prepend">{{showLang('com.lighting.pr1')}}</span>
              <span slot="append">W</span>
            </Input>
            <Input type="number" v-model="form[`count${n}`]" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
            <Input type="number" v-model="form[`adjust${n}`]" style="width: 200px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.pc')}}</span></Input>
          </div>
        </FormItem>
        <FormItem :key="`${n}-2`" prop="en1" :label="`${n}路控制方式`" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form[`mode${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in args.modes" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联计时日表：
            <Select :placeholder="showLang('save.select')" v-model="form[`planId1${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in args.plan1s" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            关联准时日表：
            <Select :placeholder="showLang('save.select')" v-model="form[`planId2${n}`]" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in args.plan2s" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
          </div>
        </FormItem>
        <FormItem :key="`${n}-3`" prop="en1" :label="`${n}路电流功率`" style="margin-right: 15px">
          <div class="flex-row">
            <Input type="number" v-model="form[`PowerLower${n}`]" style="width: 250px; margin: 0 5px;">
              <span slot="prepend">下限值</span>
              <span slot="append">%</span>
            </Input>
            <Input type="number" v-model="form[`PowerUpper${n}`]" style="width: 250px; margin: 0 5px;">
              <span slot="prepend">上限值</span>
              <span slot="append">%</span>
            </Input>
          </div>
        </FormItem>
      </template>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import PoleSelect from '../../../common/poleSelect/Index'
export default {
  name: 'ModalLampEdit',
  components: {
    PoleSelect,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    args: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        id: 0,
        lightGroupId: 0, //所属灯控分组ID
        poleId: '', //所在灯杆ID
        useType: 101, //应用类型
        deviceType: 0, //设备类型
        code: '',
        name: '',
        voltageLower: 80, //电压报警下限
        voltageUpper: 280, //电压报警上限
        tempLevel1: 50, //温度一级报警阈值
        tempLevel2: 80, //温度二级报警阈值
        tempLevel2Bright: 0, //降功率调光值
        buildDate: "2024-04-01",
        enable1: true, //通道是否启用
        before1: 250, //通道改造前功率
        after1: 100, //通道当前功率
        count1: 1, //通道光源数量
        adjust1: 1, //通道测量系数
        planId11: 0, //通道关联计时日表ID，
        planId21: 0, //通道关联准时日表ID，
        PowerLower1: 20, //1通道功率下限百分比（0-255）
        PowerUpper1: 200, //1通道功率上限百分比（0-255）

        mode1: 0, //通道控制方式
        enable2: true, //通道是否启用
        before2: 250, //通道改造前功率
        after2: 100, //通道当前功率
        count2: 1, //通道光源数量
        adjust2: 1, //通道测量系数
        planId12: 0, //通道关联计时日表ID，
        planId22: 0, //通道关联准时日表ID，
        PowerLower2: 20, //2通道功率下限百分比（0-255）
        PowerUpper2: 200, //2通道功率上限百分比（0-255）
        mode2: 0, //通道控制方式
        
      },
      poleInfo: {
        id: '',
        name: '',
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        let poleName = '';
        if (!this.isAdd) {
          for(let key in this.form){
            // console.log('edit form', key, this.item.data[key])
            this.form[key] = this.item.data[key];
          }
          poleName = this.item.data.poleName;
        } else {
          this.form.id = '';
          this.form.lightGroupId = 0;
          this.form.name = '';
          this.form.code = '';
          this.form.poleId = '';
          this.form.useType = 101;
          this.form.deviceType = 0;
          this.form.voltageAlarm = true;
          this.form.voltageLower = 80;
          this.form.voltageUpper = 280;
          this.form.tempAlarm = true;
          this.form.tempLevel1 = 50;
          this.form.tempLevel2 = 80;
          this.form.tempLevel2Bright = 0;
          this.form.buildDate = new Date().format('yyyy-MM-dd');

          this.form.enable1 = true; //通道是否启用
          this.form.before1 = 250; //通道改造前功率
          this.form.after1 = 100; //通道当前功率
          this.form.count1 = 1; //通道光源数量
          this.form.adjust1 = 1; //通道测量系数
          this.form.planId11 = 0; //通道关联计时日表ID，
          this.form.planId21 = 0; //通道关联准时日表ID，
          this.form.PowerLower1 = 50; //1通道功率下限百分比（0-255）
          this.form.PowerUpper1 = 250; //1通道功率上限百分比（0-255）
          this.form.mode1 = 0; //通道控制方式

          this.form.enable2 = true; //通道是否启用
          this.form.before2 = 250; //通道改造前功率
          this.form.after2 = 100; //通道当前功率
          this.form.count2 = 1; //通道光源数量
          this.form.adjust2 = 1; //通道测量系数
          this.form.planId12 = 0; //通道关联计时日表ID，
          this.form.planId22 = 0; //通道关联准时日表ID，
          this.form.PowerLower2 = 50; //1通道功率下限百分比（0-255）
          this.form.PowerUpper2 = 250; //1通道功率上限百分比（0-255）
          this.form.mode2 = 0; //通道控制方式
          
        }
        this.$set(this, 'poleInfo', {id: this.form.poleId, name: poleName});
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    allGroups: function(){
      // console.log('all groups', this.groupTreeSelectedNode)
      if(!this.groupTreeSelectedNode.children)return[];
      let grps = [];
      if(this.groupTreeSelectedNode.id > 0){
        grps.push({id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.title});
      }
      for(let node of this.groupTreeSelectedNode.children){
        this.getChildGroups(grps, node);
      }
      return grps;
    },
  },
  mounted: function () {
    // this.form.content.nm1=this.showLang('com.lamp.host');
    // this.form.content.nm2=this.showLang('com.lamp.assist');
    // this.form.content.nm3=this.showLang('com.lamp.assist');
    // this.form.content.nm4=this.showLang('com.lamp.assist');
    // this.form.content.tp1=this.showLang('com.state.default');
    // this.form.content.tp2=this.showLang('com.state.default');
    // this.form.content.tp3=this.showLang('com.state.default');
    // this.form.content.tp4=this.showLang('com.state.default');
    // console.log('modal level edit mounted', this.alarmTypes, this.alarmGroupList)
  },
  methods: {
    loadPoleList: function(params){
      console.log('load pole list', params);
    },
    getChildGroups: function(list, node){
      list.push({id: node.id, name: node.title});
      for(let n of node.children){
        this.getChildGroups(list, n);
      }
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.poleInfo.id.length == 0) {
        this.$Message.warning("请选择所在灯杆");
        return;
      }
      this.form.poleId = this.poleInfo.id;
      // if (this.form.deviceType <= 0) {
      //   this.$Message.warning("请选择设备类型");
      //   return;
      // }
      if (this.form.code.trim().length == 0) {
        this.$Message.warning("请输入通信ID");
        return;
      }
      if(!this.form.enable1 && !this.form.enable2){
        this.$Message.warning('至少启用一个通道');
        return;
      }
      this.$axios.post(`device/light/SavePoleLampLt68Config`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.edit-form-item{
  border: solid 1px red;
  margin-top: 5px;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>